import React from "react";

interface IInputText {
  label: string;
  value: string;
  onChangeValue: (nextValue: string) => void;
  id: string;
  type?: "text" | "password";
}

const InputText: React.FC<IInputText> = ({
  label,
  value,
  onChangeValue,
  id,
  type = "text",
}) => {
  return (
    <div className="w-full max-w-[670px] ">
      <label
        className="mb-3 ml-[6px] block font-calibri-bold text-xl uppercase text-gray-dark"
        htmlFor={id}
      >
        {label}
      </label>
      <input
        className="h-[50px] w-full rounded-md border border-gray px-6"
        id={id}
        type={type}
        value={value}
        onChange={(event) => onChangeValue(event.currentTarget.value)}
      />
    </div>
  );
};

export default InputText;
