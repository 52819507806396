import React, {
  createContext,
  useState,
  useEffect,
  ReactNode,
  Dispatch,
  SetStateAction,
} from 'react';
import { useNavigate } from 'react-router-dom';

import { retrieveToken } from "../utils/sessionStorage";

import { PAGES } from "../constants";

interface IAuthContext {
  isLogged: boolean;
  setIsLogged: Dispatch<SetStateAction<boolean>>;
  token: string;
  setToken: Dispatch<SetStateAction<string>>;
}

const AuthContext = createContext<IAuthContext>({
  isLogged: false,
  setIsLogged: () => null,
  token: '',
  setToken: () => null,
});

export const AuthContextProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [isLogged, setIsLogged] = useState(false);
  const [token, setToken] = useState('');
  const [isCheckingAuth, setIsCheckingAuth] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    if(!isCheckingAuth) return;
    const tokenInSession = retrieveToken();
    if(tokenInSession) {
      setToken(tokenInSession);
      setIsLogged(true);
      navigate(PAGES.USERS.URL);
    }
    setIsCheckingAuth(false);
  }, [navigate, isCheckingAuth])

  return (
    <AuthContext.Provider value={{ isLogged, setIsLogged, token, setToken }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
