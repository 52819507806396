import { BrowserRouter, Routes, Route } from 'react-router-dom';

import { AuthContextProvider } from './store/AuthContext';
import { DataContextProvider } from "./store/DataContext";

import Login from './pages/Login';
import Versions from './pages/Versions';
import Users from './pages/Users';
import Header from './components/Header';

import { PAGES } from './constants';

function App() {
  return (
    <BrowserRouter>
      <AuthContextProvider>
        <DataContextProvider>
          <>
            <Header />
            <Routes>
              <Route path={PAGES.LOGIN.URL} element={<Login />} />
              <Route path={PAGES.USERS.URL} element={<Users />} />
              <Route path={PAGES.VERSIONS.URL} element={<Versions />} />
            </Routes>
          </>
        </DataContextProvider>
      </AuthContextProvider>
    </BrowserRouter>
  );
}

export default App;
