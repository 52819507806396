import React, { ReactNode, useEffect } from "react";
import { createPortal } from "react-dom";

interface IModal {
  children: ReactNode;
}

const Modal: React.FC<IModal> = ({ children }) => {
  const wrapper = document.getElementById("modal-container");
  useEffect(() => {
    if (!wrapper) return;
    wrapper.style.maxHeight = "100vh";
    wrapper.style.overflow = "hidden";
    return () => {
      wrapper.style.maxHeight = "auto";
      wrapper.style.overflow = "auto";
    };
  });
  if (!wrapper) return null;
  return createPortal(
    <div className="fixed top-0 left-0 right-0 bottom-0 z-50 flex h-screen items-center justify-center  overflow-auto bg-gray">
      <div className="w-full max-w-5xl border border-gray-dark bg-white p-[26px] ">
        {children}
      </div>
    </div>,
    wrapper
  );
};

export default Modal;
