import React, { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import cn from "classnames";

import AuthContext from "../store/AuthContext";
import DataContext from "../store/DataContext";

import Table from "../components/table/Table";
import Cell from "../components/table/Cell";
import Modal from "../components/Modal";
import Button from "../components/Button";
import Loader from "../components/Loader";
import ActivateButton from "../components/ActivateButton";

import {
  ChannelKeys,
  IPackage,
  IPackageWithDependencies,
  IVersions,
  PackageStatus,
} from "../types/packages";

import { CHANNELS, PAGES } from "../constants";

interface IVersionNumber {
  versions: IVersions;
  column: ChannelKeys;
  isReadyToUpdate: boolean;
}

interface IUpdateInfos {
  oldVersion: string;
  newVersion: string;
  channel: string;
  packageName: string;
  channelId: number;
  packageId: number;
}
const VersionNumber: React.FC<IVersionNumber> = ({
  versions,
  column,
  isReadyToUpdate,
}) => {
  const versionDisplayed = useMemo(() => {
    const current = versions[column];
    if (current.status === PackageStatus.InProgress) {
      if (column === "korian_public") return versions.korian_beta;
      if (column === "korian_beta") return versions.korian_available;
    }
    return current;
  }, [versions, column]);

  const isUpdating = useMemo(
    () => versions[column]?.status === PackageStatus.InProgress ?? false,
    [versions, column]
  );
  return (
    <div
      className={cn("h-[24px]", {
        "font-bold text-red": isReadyToUpdate,
        "font-bold text-yellow": isUpdating,
      })}
    >
      {versionDisplayed.version.split(".").map((item, index) => (
        <span className="inline-block w-[30px]" key={index}>
          {item}
          {index < 3 && "."}
        </span>
      ))}
    </div>
  );
};

const Empty = () => <div className="h-[24px]" />;

const Line: React.FC<{
  currentPackage: IPackageWithDependencies;
  mapIndex: number;
}> = ({ currentPackage, mapIndex }) => {
  const { token } = useContext(AuthContext);
  const { handleUpdatePackageVersion } = useContext(DataContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  // const [modalUpdateInfos, setModalUpdateInfos] = useState<IUpdateInfos[]>([]);
  const [mainPackageUpdateInfo, setMainPackageUpdateInfos] =
    useState<IUpdateInfos>();
  const [dependenciesPackageUpdateInfos, setDependenciesPackageUpdateInfos] =
    useState<IUpdateInfos[]>([]);
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => {
    setMainPackageUpdateInfos(undefined);
    setDependenciesPackageUpdateInfos([]);
    setIsModalOpen(false);
  };

  const checkIsWaitingForUpdate = (
    channelKey: string,
    packageToTest: IPackage | IPackageWithDependencies
  ): boolean => {
    const channelInfos = CHANNELS.find((channel) => channel.key === channelKey);

    if (
      !channelInfos ||
      !channelInfos.previous ||
      !packageToTest.versions[channelInfos.previous].version
    )
      return false;

    return (
      packageToTest.versions[channelKey].version !==
      packageToTest.versions[channelInfos.previous].version
    );
  };

  const onClickActivate = (currentChannel: ChannelKeys) => {
    const channelInfos = CHANNELS.find(
      (kChannel) => kChannel.key === currentChannel
    );
    if (!channelInfos?.previous) return;
    if (currentPackage.dependencies.length !== 0) {
      const depsUpdateInfos: IUpdateInfos[] = [];
      currentPackage.dependencies.forEach((item) => {
        if (checkIsWaitingForUpdate(currentChannel, item)) {
          depsUpdateInfos.push({
            oldVersion: item.versions[currentChannel].version,
            newVersion:
              item.versions[channelInfos.previous as ChannelKeys].version,
            channel: channelInfos.name,
            packageName: item.packageName,
            channelId: channelInfos.id,
            packageId: item.packageId,
          });
        }
      });
      setDependenciesPackageUpdateInfos(depsUpdateInfos);
    }
    if (checkIsWaitingForUpdate(currentChannel, currentPackage)) {
      setMainPackageUpdateInfos({
        oldVersion: currentPackage.versions[currentChannel].version,
        newVersion: currentPackage.versions[channelInfos.previous].version,
        channel: channelInfos.name,
        packageName: currentPackage.packageName,
        channelId: channelInfos.id,
        packageId: currentPackage.packageId,
      });
    }

    openModal();
  };

  const handleActivateVersions = async () => {
    const packagesToUpdate: string[] = [];
    if (mainPackageUpdateInfo) {
      packagesToUpdate.push(
        `${mainPackageUpdateInfo.packageId}@${mainPackageUpdateInfo.newVersion}`
      );
    }
    if (dependenciesPackageUpdateInfos.length !== 0) {
      dependenciesPackageUpdateInfos.forEach((item) => {
        packagesToUpdate.push(`${item.packageId}@${item.newVersion}`);
      });
    }
    await handleUpdatePackageVersion({
      token,
      packagesToUpdate,
      channelId:
        (mainPackageUpdateInfo?.channelId as number) ??
        dependenciesPackageUpdateInfos[0].channelId,
    });
    closeModal();
  };

  const activatingInfos = useMemo(() => {
    let activating: boolean = false;
    let channels: ChannelKeys[] = [];
    Object.entries(currentPackage.versions).forEach(([channel, infos]) => {
      if (infos.status === PackageStatus.InProgress) {
        channels.push(channel as ChannelKeys);
        activating = true;
      }
    });

    if (currentPackage.dependencies.length !== 0) {
      currentPackage.dependencies.forEach((depsPackage) => {
        Object.entries(depsPackage.versions).forEach(([channel, infos]) => {
          if (infos.status === PackageStatus.InProgress) {
            channels.push(channel as ChannelKeys);
            activating = true;
          }
        });
      });
    }
    return {
      activating,
      channels,
    };
  }, [currentPackage]);
  return (
    <>
      {isModalOpen && (
        <Modal>
          <div className="font-calibri text-text-xl">
            {!!mainPackageUpdateInfo && (
              <div key={mainPackageUpdateInfo.packageName}>
                <p>
                  - Le paquet{" "}
                  <span className="font-calibri-bold">
                    {mainPackageUpdateInfo.packageName}
                  </span>{" "}
                  va passer de la version{" "}
                  <span className="font-calibri-bold">
                    {mainPackageUpdateInfo.oldVersion}
                  </span>{" "}
                  à la version{" "}
                  <span className="font-calibri-bold">
                    {mainPackageUpdateInfo.newVersion}
                  </span>{" "}
                  sur le channel{" "}
                  <span className="font-calibri-bold">
                    {mainPackageUpdateInfo.channel}
                  </span>
                </p>
              </div>
            )}

            {dependenciesPackageUpdateInfos.length !== 0 && (
              <div>
                {dependenciesPackageUpdateInfos.map((dependency) => (
                  <div key={dependency.packageName}>
                    <p>
                      - Le paquet{" "}
                      <span className="font-calibri-bold">
                        {dependency.packageName}
                      </span>{" "}
                      va passer de la version{" "}
                      <span className="font-calibri-bold">
                        {dependency.oldVersion}
                      </span>{" "}
                      à la version{" "}
                      <span className="font-calibri-bold">
                        {dependency.newVersion}
                      </span>{" "}
                      sur le channel{" "}
                      <span className="font-calibri-bold">
                        {dependency.channel}
                      </span>
                    </p>
                  </div>
                ))}
              </div>
            )}

            {/*<p className="mb-[10px]">Notes de version :</p>*/}
            {/*<textarea className="mb-[33px] min-h-[190px] w-full rounded-md border p-3 font-calibri" />*/}
            <div className="mt-[33px] flex flex-col items-center">
              <p className="mb-[33px]">
                Êtes-vous sûr(e) de vouloir effectuer cette opération ?{" "}
              </p>
              <Button
                className=" mb-[33px]"
                label="Oui"
                onClick={handleActivateVersions}
                icon="tick"
              />
              <Button
                className=" mb-[33px]"
                label="Non"
                onClick={closeModal}
                icon="cross"
              />
            </div>
          </div>
        </Modal>
      )}
      {activatingInfos.activating && (
        <tr
          className={cn("h-[25px] font-bold text-yellow ", {
            "bg-gray-extra-light": mapIndex % 2,
          })}
        >
          <Cell size={25} />
          <Cell size={25}>
            {activatingInfos.channels.includes(ChannelKeys.KorianAvailable) && (
              <p>Activation en cours ... </p>
            )}
          </Cell>
          <Cell size={25}>
            {activatingInfos.channels.includes(ChannelKeys.KorianBeta) && (
              <p>Activation en cours ... </p>
            )}
          </Cell>
          <Cell size={25}>
            {activatingInfos.channels.includes(ChannelKeys.KorianPublic) && (
              <p>Activation en cours ... </p>
            )}
          </Cell>
        </tr>
      )}
      <tr
        key={currentPackage.packageId}
        className={cn("h-[75px]", {
          "bg-gray-extra-light": mapIndex % 2,
        })}
      >
        {currentPackage.dependencies.length !== 0 ? (
          <>
            <Cell size={25} alignLeft className="pl-3">
              <p>{currentPackage.packageName}</p>
              <ul className="ml-[31px] border-l border-gray-dark pl-[31px]">
                {currentPackage.dependencies.map((dependency) => (
                  <li key={dependency.packageId}>{dependency.packageName}</li>
                ))}
              </ul>
            </Cell>
            <Cell size={25} className="relative">
              {Object.entries(currentPackage.versions.korian_available)
                .length !== 0 ? (
                <VersionNumber
                  versions={currentPackage.versions}
                  column={ChannelKeys.KorianAvailable}
                  isReadyToUpdate={checkIsWaitingForUpdate(
                    ChannelKeys.KorianAvailable,
                    currentPackage
                  )}
                />
              ) : (
                <Empty />
              )}
              {currentPackage.dependencies.map((dependency) =>
                Object.entries(dependency.versions.korian_available).length !==
                0 ? (
                  <VersionNumber
                    key={dependency.packageId}
                    versions={dependency.versions}
                    column={ChannelKeys.KorianAvailable}
                    isReadyToUpdate={checkIsWaitingForUpdate(
                      ChannelKeys.KorianAvailable,
                      dependency
                    )}
                  />
                ) : (
                  <Empty key={dependency.packageId} />
                )
              )}
            </Cell>

            <Cell size={25} className="relative">
              {Object.entries(currentPackage.versions.korian_beta).length !==
              0 ? (
                <div>
                  <VersionNumber
                    versions={currentPackage.versions}
                    column={ChannelKeys.KorianBeta}
                    isReadyToUpdate={checkIsWaitingForUpdate(
                      ChannelKeys.KorianBeta,
                      currentPackage
                    )}
                  />
                  {checkIsWaitingForUpdate(
                    ChannelKeys.KorianBeta,
                    currentPackage
                  ) && (
                    <ActivateButton
                      onClick={() => onClickActivate(ChannelKeys.KorianBeta)}
                    />
                  )}
                </div>
              ) : (
                <Empty />
              )}

              {currentPackage.dependencies.map((dependency) =>
                Object.entries(dependency.versions.korian_beta).length !== 0 ? (
                  <div key={dependency.packageId}>
                    <VersionNumber
                      versions={dependency.versions}
                      column={ChannelKeys.KorianBeta}
                      isReadyToUpdate={checkIsWaitingForUpdate(
                        ChannelKeys.KorianBeta,
                        dependency
                      )}
                    />
                    {checkIsWaitingForUpdate(
                      ChannelKeys.KorianBeta,
                      dependency
                    ) && (
                      <ActivateButton
                        onClick={() => onClickActivate(ChannelKeys.KorianBeta)}
                      />
                    )}
                  </div>
                ) : (
                  <Empty key={dependency.packageId} />
                )
              )}
            </Cell>
            <Cell size={25} className="relative">
              {Object.entries(currentPackage.versions.korian_public).length !==
              0 ? (
                <>
                  <VersionNumber
                    versions={currentPackage.versions}
                    column={ChannelKeys.KorianPublic}
                    isReadyToUpdate={checkIsWaitingForUpdate(
                      ChannelKeys.KorianPublic,
                      currentPackage
                    )}
                  />
                  {checkIsWaitingForUpdate(
                    ChannelKeys.KorianPublic,
                    currentPackage
                  )}
                </>
              ) : (
                <Empty />
              )}
              {currentPackage.dependencies.map((dependency) =>
                Object.entries(dependency.versions.korian_public).length !==
                0 ? (
                  <div key={dependency.packageId}>
                    {checkIsWaitingForUpdate(
                      ChannelKeys.KorianPublic,
                      dependency
                    ) ? (
                      <>
                        <ActivateButton
                          onClick={() =>
                            onClickActivate(ChannelKeys.KorianPublic)
                          }
                        />
                        <VersionNumber
                          versions={dependency.versions}
                          column={ChannelKeys.KorianPublic}
                          isReadyToUpdate
                        />
                      </>
                    ) : (
                      <VersionNumber
                        versions={dependency.versions}
                        column={ChannelKeys.KorianPublic}
                        isReadyToUpdate={false}
                      />
                    )}
                  </div>
                ) : (
                  <Empty key={dependency.packageId} />
                )
              )}
            </Cell>
          </>
        ) : (
          <>
            <Cell size={25} alignLeft>
              <p>{currentPackage.packageName}</p>
            </Cell>
            <Cell size={25}>
              {Object.entries(currentPackage.versions.korian_available)
                .length !== 0 ? (
                <VersionNumber
                  versions={currentPackage.versions}
                  column={ChannelKeys.KorianAvailable}
                  isReadyToUpdate={checkIsWaitingForUpdate(
                    ChannelKeys.KorianAvailable,
                    currentPackage
                  )}
                />
              ) : (
                <Empty />
              )}
            </Cell>
            <Cell size={25} className="relative">
              {Object.entries(currentPackage.versions.korian_beta).length !==
                0 && (
                <VersionNumber
                  versions={currentPackage.versions}
                  column={ChannelKeys.KorianBeta}
                  isReadyToUpdate={checkIsWaitingForUpdate(
                    ChannelKeys.KorianBeta,
                    currentPackage
                  )}
                />
              )}
              {checkIsWaitingForUpdate(
                ChannelKeys.KorianBeta,
                currentPackage
              ) && (
                <ActivateButton
                  onClick={() => onClickActivate(ChannelKeys.KorianBeta)}
                />
              )}
            </Cell>
            <Cell size={25} className="relative">
              {Object.entries(currentPackage.versions.korian_public).length !==
              0 ? (
                <VersionNumber
                  versions={currentPackage.versions}
                  column={ChannelKeys.KorianPublic}
                  isReadyToUpdate={checkIsWaitingForUpdate(
                    ChannelKeys.KorianPublic,
                    currentPackage
                  )}
                />
              ) : (
                <Empty />
              )}
              {checkIsWaitingForUpdate(
                ChannelKeys.KorianPublic,
                currentPackage
              ) && (
                <ActivateButton
                  onClick={() => onClickActivate(ChannelKeys.KorianPublic)}
                />
              )}
            </Cell>
          </>
        )}
      </tr>
    </>
  );
};

const Versions: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();
  const { isLogged } = useContext(AuthContext);
  const { packages } = useContext(DataContext);

  useEffect(() => {
    if (!isLogged) {
      navigate(PAGES.LOGIN.URL);
    } else {
      setIsLoading(false);
    }
  }, [isLogged, navigate]);

  if (isLoading || !packages)
    return (
      <div className="bg-yellow-500 flex h-[calc(100vh_-_46px)] w-screen items-center justify-center ">
        <Loader className="h-[100px] w-[100px]" />
      </div>
    );
  return (
    <div className="text-xl">
      <Table
        headers={[
          "NOM DU COMPOSANT",
          "Version EN ATTENTE",
          "Version BETA",
          "Version PUBLIC",
        ]}
      >
        {packages.map((currentPackage, index) => (
          <Line
            key={currentPackage.packageId}
            currentPackage={currentPackage}
            mapIndex={index + 1}
          />
        ))}
      </Table>
    </div>
  );
};

export default Versions;
