import axios from "axios";
import pckg from "../../package.json";
import { IUpdatePackageVersion, IUpdateUserChanner } from "../types/api";

const API = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    appName: pckg.name,
    appVersion: pckg.version,
    "Content-Type": "application/json",
  },
});

const useApi = () => {
  const login = async (login: string, password: string) => {
    try {
      const body = JSON.stringify({
        login,
        password,
      });
      const response = await API.post("/users/auth", body);
      return response.data;
    } catch (e) {
      return false;
    }
  };

  const logout = async (token: string) => {
    try {
      const response = await API.post(
        "/users/logout",
        {},
        {
          headers: {
            ticket: token,
          },
        }
      );
      return response.status;
    } catch (e) {
      return false;
    }
  };

  const getUsers = async (token: string) => {
    try {
      const response = await API.get("/users", {
        headers: {
          ticket: token,
        },
      });
      return response.data;
    } catch (e) {
      return false;
    }
  };

  const getPackages = async (token: string) => {
    try {
      const response = await API.get("/packages", {
        headers: {
          ticket: token,
        },
      });
      return response.data;
    } catch (e) {
      return false;
    }
  };

  const updatePackageVersion = async ({
    token,
    packagesToUpdate,
    channelId,
  }: IUpdatePackageVersion) => {
    try {
      const body = {
        packages: packagesToUpdate,
        channelId,
      };
      const response = await API.post(`/packages/activate`, body, {
        headers: {
          ticket: token,
        },
      });
      return response.status === 200;
    } catch (e) {
      return false;
    }
  };

  const updateUserChannel = async ({
    token,
    userId,
    channelId,
  }: IUpdateUserChanner) => {
    try {
      const body = {
        channelId,
      };
      const response = await API.patch(`/users/${userId}/channel`, body, {
        headers: {
          ticket: token,
        },
      });
      return response.status === 200;
    } catch (e) {
      return false;
    }
  };

  return {
    login,
    logout,
    getUsers,
    getPackages,
    updatePackageVersion,
    updateUserChannel,
  };
};

export default useApi;
