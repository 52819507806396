import React, { ReactNode, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import AuthContext from '../store/AuthContext';
import useApi from '../hooks/useApi';

import InputText from '../components/InputText';
import Button from '../components/Button';

import { saveToken } from "../utils/sessionStorage";

import { PAGES } from '../constants';

const FormLine: React.FC<{ children: ReactNode }> = ({ children }) => {
  return <div className="mb-16 w-full">{children}</div>;
};

const Login = () => {
  const [inputValues, setInputValues] = useState({
    username: '',
    password: '',
  });
  const [errorMessage, setErrorMessage] = useState<string>();
  const { setIsLogged, setToken } = useContext(AuthContext);
  const navigation = useNavigate();
  const { login } = useApi();

  const handleLogin = async () => {
    const { username, password } = inputValues
    if(!username || !password) {
      setErrorMessage('Les champs Identifiant et Mot de passe sont obligatoires.');
      return;
    }
    const response = await login(username, password);

    if(!response) {
      setErrorMessage('Le couple Identifiant / Mot de passe ne correspond à aucun compte. Merci de réessayer.');
      return;
    }
    setToken(response.ticket);
    saveToken(response.ticket);
    setIsLogged(true);
    navigation(PAGES.USERS.URL);
  };

  return (
    <div className="flex min-h-[calc(100vh_-_46px)] w-full items-center justify-center bg-gray-light">
      <div className="flex w-full max-w-3xl flex-col items-center justify-center border border-gray-dark bg-white px-[27px] py-[39px]">
        {errorMessage && (
          <p className="mb-6 text-center text-xl font-bold text-red">
            {errorMessage}
          </p>
        )}
        <FormLine>
          <InputText
            label="Identifiant"
            value={inputValues.username}
            onChangeValue={(nextValue) =>
              setInputValues({ ...inputValues, username: nextValue })
            }
            id="username"
          />
        </FormLine>
        <FormLine>
          <InputText
            label="Mot de passe"
            type="password"
            value={inputValues.password}
            onChangeValue={(nextValue) =>
              setInputValues({ ...inputValues, password: nextValue })
            }
            id="password"
          />
        </FormLine>
        <Button label="Se connecter" icon="tick" onClick={handleLogin} />
      </div>
    </div>
  );
};

export default Login;
