import React from 'react';
import cn from 'classnames';

interface IButton {
  label: string;
  onClick: () => void;
  icon?: 'tick' | 'cross';
  className?: string;
}

const Button: React.FC<IButton> = ({
  label,
  onClick,
  icon,
  className = '',
}) => {
  return (
    <button
      className={cn(
        'relative h-[50px] w-full max-w-[340px] rounded-md border border-gray shadow-md hover:shadow-sm',
        {
          [className]: className !== '',
        },
      )}
      type="button"
      onClick={(_) => onClick()}
    >
      {icon && (
        <span className="absolute left-6 top-0 flex h-full items-center">
          {icon === 'tick' ? '✔' : icon === 'cross' ? '❌' : null}
        </span>
      )}
      <span className="font-calibri-bold text-xl text-gray-dark">{label}</span>
    </button>
  );
};

export default Button;
