import React, { useState, useContext } from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
import cn from "classnames";

import AuthContext from "../store/AuthContext";
import useApi from "../hooks/useApi";

import Modal from "./Modal";
import Button from "./Button";

import { deleteSavedToken } from "../utils/sessionStorage";

import { PAGES } from "../constants";

interface IItem {
  label: string;
  to: string;
}
const Item: React.FC<IItem> = ({ label, to }) => {
  const location = useLocation();
  const isCurrent = location.pathname === to;
  return (
    <Link
      to={to}
      className="flex h-full cursor-pointer flex-col justify-center"
    >
      <span className="text-xl px-6 font-calibri-bold uppercase text-white">
        {label}
      </span>
      <span
        className={cn("h-[9px] w-full", {
          "border border-gray bg-blue": isCurrent,
        })}
      />
    </Link>
  );
};

const Header = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { isLogged, setIsLogged, setToken, token } = useContext(AuthContext);
  const [errorMessage, setErrorMessage] = useState<string>();
  const navigation = useNavigate();
  const { logout } = useApi();

  const handleToggleModal = () => {
    setErrorMessage(undefined);
    setIsModalOpen((prevState) => !prevState);
  };
  const handleLogout = async () => {
    await logout(token);
    setToken("");
    deleteSavedToken();
    setIsLogged(false);
    navigation(PAGES.LOGIN.URL);
    handleToggleModal();
  };
  return (
    <header className="flex h-[46px] w-full bg-gray-dark">
      {isModalOpen && (
        <Modal>
          <div className="flex flex-col items-center">
            {errorMessage && (
              <p className="text-xl mb-6 text-center font-bold text-red">
                {errorMessage}
              </p>
            )}
            <p className="text-xl mb-[42px] font-calibri-bold uppercase">
              Étes-vous sur de vouloir vous déconnecter ?
            </p>
            <Button
              className="mb-[35px] uppercase"
              icon="tick"
              label="Oui"
              onClick={handleLogout}
            />
            <Button
              className="uppercase"
              icon="cross"
              label="Non"
              onClick={handleToggleModal}
            />
          </div>
        </Modal>
      )}
      <div className="text-xl flex h-full w-[250px] items-center justify-center border border-gray bg-blue font-calibri-bold uppercase text-center px-2">
        <p className="text-white">Déploiement Happyneuron</p>
      </div>
      <p className="text-xl ml-3 mr-6 flex h-full items-center font-calibri-bold text-white">
        {">"}
      </p>
      {isLogged ? (
        <div className="flex w-full justify-between">
          <div className="flex">
            <Item to={PAGES.USERS.URL} label={PAGES.USERS.NAME} />
            <Item to={PAGES.VERSIONS.URL} label={PAGES.VERSIONS.NAME} />
          </div>

          <button className="mr-12" onClick={handleToggleModal}>
            <span>🔒</span>
            <span className="text-xl ml-2 font-calibri-bold uppercase text-white underline">
              Se déconnecter
            </span>
          </button>
        </div>
      ) : (
        <div>
          <Item to={PAGES.LOGIN.URL} label={PAGES.LOGIN.NAME} />
        </div>
      )}
    </header>
  );
};

export default Header;
