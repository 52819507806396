const TOKEN_IDENTIFIER = "user_token";

export const saveToken = (token: string) =>
  window.sessionStorage.setItem(TOKEN_IDENTIFIER, token);

export const retrieveToken = () =>
  window.sessionStorage.getItem(TOKEN_IDENTIFIER);

export const deleteSavedToken = () =>
  window.sessionStorage.removeItem(TOKEN_IDENTIFIER);
