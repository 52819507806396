export enum PackageStatus {
  InProgress = "in_progress",
  Enabled = "enabled",
}

export enum ChannelKeys {
  Waiting = "waiting",
  Public = "public",
  Local = "local",
  Beta = "beta",
  KorianAvailable = "korian_available",
  KorianPublic = "korian_public",
  KorianBeta = "korian_beta",
}

export interface IChannelInfo {
  id: number;
  name: string;
  key: ChannelKeys;
  previous: ChannelKeys | null;
}

export interface IVersion {
  version: string;
  status: PackageStatus;
}

export interface IVersions {
  [key: string]: IVersion;
  korian_available: IVersion;
  korian_public: IVersion;
  korian_beta: IVersion;
}

export interface IPackage {
  packageId: number;
  packageName: string;
  versions: IVersions;
}

export interface IPackageWithDependencies extends IPackage {
  dependencies: IPackage[] | [];
}
