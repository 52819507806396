import React, { ReactNode } from "react";

interface ITable {
  headers: string[];
  children: ReactNode;
}

const Table: React.FC<ITable> = ({ children, headers }) => {
  return (
    <div className="min-h-[calc(100vh_-_46px)] ">
      <table className="w-full">
        <thead>
          <tr>
            {headers.map((header, index) => (
              <th
                key={index}
                className="text-xl sticky top-0 z-40 border-r border-gray border-gray bg-white px-2 text-center font-calibri-bold after:absolute after:-bottom-[1px] after:left-0 after:right-0 after:h-[1px] after:w-full after:bg-gray after:content-['']"
              >
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="border-b border-gray">{children}</tbody>
      </table>
    </div>
  );
};

export default Table;
