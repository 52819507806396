import React, { ReactNode } from "react";
import cn from "classnames";

interface ICell {
  children?: ReactNode;
  size: number;
  alignLeft?: boolean;
  className?: string;
}

const Cell: React.FC<ICell> = ({
  children,
  size,
  alignLeft = false,
  className = "",
}) => {
  return (
    <td
      width={`${size}%`}
      className={cn(` border-r border-gray px-2`, {
        "text-center": !alignLeft,
        [className]: className !== "",
      })}
    >
      {children && children}
    </td>
  );
};

export default Cell;
