import React from "react";

interface IActivateButton {
  onClick: () => void;
}

const ActivateButton: React.FC<IActivateButton> = ({ onClick }) => (
  <div
    className="absolute -left-[25px] top-[calc(50%_-_25px)] z-40 flex h-[50px] w-[50px] cursor-pointer items-center justify-around rounded-md border border-gray bg-white px-3 shadow-md hover:shadow-sm lg:-left-[75px] lg:w-[150px]"
    role="button"
    onClick={onClick}
  >
    <span className="hidden lg:block">Activer</span>
    <span className="cursor-pointer">▶</span>
  </div>
);

export default ActivateButton;
