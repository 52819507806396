import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";

import Table from "../components/table/Table";
import Cell from "../components/table/Cell";
import SelectCanal from "../components/table/SelectCanal";
import Loader from "../components/Loader";

import AuthContext from "../store/AuthContext";
import DataContext from "../store/DataContext";

import { PAGES } from "../constants";
import { IUser } from "../types/users";

const Line: React.FC<{
  user: IUser;
}> = ({ user }) => {
  const [isLoadingUserChannel, setIsLoadingUserChannel] = useState(false);
  const { token } = useContext(AuthContext);
  const { handleUpdateUserChannel } = useContext(DataContext);

  const onUpdateUser = async ({
    userId,
    channelId,
  }: {
    userId: number;
    channelId: number;
  }) => {
    setIsLoadingUserChannel(true);
    await handleUpdateUserChannel({
      token,
      userId,
      channelId,
    });
    setIsLoadingUserChannel(false);
  };
  return (
    <tr>
      <Cell alignLeft size={25}>
        <span className="font-calibri-bold">{user.login}</span>
      </Cell>
      <Cell alignLeft size={25}>
        <span className="font-calibri-bold">
          {user.lastName} {user.firstName}
        </span>
      </Cell>
      <Cell alignLeft size={25}>
        <a
          href={`mailto:${user.email}`}
          className="cursor-pointer underline underline-offset-1"
        >
          {user.email}
        </a>
      </Cell>
      <Cell size={25}>
        <SelectCanal
          isLoading={isLoadingUserChannel}
          selected={user.channelId}
          onSelect={(newChannel) =>
            onUpdateUser({
              userId: user.userId,
              channelId: newChannel,
            })
          }
        />
      </Cell>
    </tr>
  );
};

const Users = () => {
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const { isLogged } = useContext(AuthContext);
  const { users } = useContext(DataContext);

  useEffect(() => {
    if (!isLogged) {
      navigate(PAGES.LOGIN.URL);
    } else {
      setIsLoading(false);
    }
  }, [isLogged, navigate]);

  if (isLoading || !users)
    return (
      <div className="bg-yellow-500 flex h-[calc(100vh_-_46px)] w-screen items-center justify-center ">
        <Loader className="h-[100px] w-[100px]" />
      </div>
    );

  return (
    <div className="text-xl w-full">
      <Table
        headers={["Login", "Nom prénom de l'utilisateur", "Email", "Canal"]}
      >
        {users?.map((user) => (
          <Line user={user} key={user.userId} />
        ))}
      </Table>
    </div>
  );
};

export default Users;
