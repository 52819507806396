import { ChannelKeys, IChannelInfo } from "./types/packages";

export const PAGES = {
  LOGIN: {
    URL: "/",
    NAME: "Connexion",
  },
  USERS: {
    URL: "/users",
    NAME: "Liste des utilisateurs",
  },
  VERSIONS: {
    URL: "/versions",
    NAME: "Liste des versions",
  },
};

export const CHANNELS: IChannelInfo[] = [
  {
    id: 0,
    name: "En attente",
    key: ChannelKeys.Waiting,
    previous: null,
  },
  {
    id: 1,
    name: "Public",
    key: ChannelKeys.Public,
    previous: null,
  },
  {
    id: 2,
    name: "Locale",
    key: ChannelKeys.Local,
    previous: null,
  },
  {
    id: 3,
    name: "Bêta",
    key: ChannelKeys.Beta,
    previous: null,
  },
  {
    id: 4,
    name: "En attente",
    key: ChannelKeys.KorianAvailable,
    previous: null,
  },
  {
    id: 6,
    name: "Bêta",
    key: ChannelKeys.KorianBeta,
    previous: ChannelKeys.KorianAvailable,
  },
  {
    id: 5,
    name: "Public",
    key: ChannelKeys.KorianPublic,
    previous: ChannelKeys.KorianBeta,
  },
];
export const KORIAN_CHANNELS_IDS_SELECTABLE = [5, 6];
export const TIME_BETWEEN_REFRESHES = 30_000;
