import React, { useState, useEffect } from "react";

import OutsideClickHandler from "react-outside-click-handler";
import cn from "classnames";

import Loader from "../Loader";

import { CHANNELS, KORIAN_CHANNELS_IDS_SELECTABLE } from "../../constants";

interface ISelectCanal {
  selected: number;
  onSelect: (status: number) => void;
  isLoading: boolean;
}

interface ICanal {
  id: number;
  name: string;
}

interface IOption {
  canalId: number;
  label: string;
  onSelect?: () => void;
}

interface ITag {
  canalId: number;
}

const Tag: React.FC<ITag> = ({ canalId }) => (
  <span className="flex h-[26px] w-[26px] flex-none items-center justify-center rounded-full bg-yellow">
    {canalId}
  </span>
);

const Option: React.FC<IOption> = ({ canalId, label, onSelect }) => {
  return (
    <li
      className="flex min-h-[50px] cursor-pointer items-center px-3 text-left hover:bg-gray-light"
      onClick={onSelect ? onSelect : () => null}
    >
      <Tag canalId={canalId} />
      <span className="ml-3">{label}</span>
    </li>
  );
};

const SelectCanal: React.FC<ISelectCanal> = ({
  selected,
  onSelect,
  isLoading,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentCanal, setCurrentCanal] = useState<ICanal>(CHANNELS[0]);

  useEffect(() => {
    const current = CHANNELS.find((canal) => canal.id === selected);
    if (!current) return;
    setCurrentCanal(current);
  }, [selected]);

  return (
    <OutsideClickHandler onOutsideClick={() => setIsOpen(false)}>
      <div
        className={cn("h-[50px] w-full  cursor-pointer")}
        onClick={() => setIsOpen((prevState) => !prevState)}
      >
        {isLoading ? (
          <div className="flex h-full w-full items-center justify-center rounded-md border border-gray-dark ">
            <Loader className="h-[35px]" />
          </div>
        ) : (
          <>
            <div
              className={cn(
                "flex min-h-[50px] items-center rounded-md border border-gray-dark px-3 text-left",
                {
                  "rounded-b-none": isOpen,
                }
              )}
            >
              <Tag canalId={currentCanal.id} />
              <span className="ml-2">{currentCanal.name}</span>
            </div>
            {isOpen && (
              <ul
                className={cn(
                  "relative w-full border border-t-0 border-gray-dark bg-white",
                  {
                    "relative z-50": isOpen,
                  }
                )}
              >
                {CHANNELS.filter((canal) =>
                  KORIAN_CHANNELS_IDS_SELECTABLE.includes(canal.id)
                ).map((canal) => (
                  <Option
                    key={canal.id}
                    canalId={canal.id}
                    label={canal.name}
                    onSelect={() => onSelect(canal.id)}
                  />
                ))}
              </ul>
            )}
          </>
        )}
      </div>
    </OutsideClickHandler>
  );
};

export default SelectCanal;
