import React, {
  createContext,
  ReactElement,
  useContext,
  useEffect,
  useState,
  useCallback,
} from "react";
import { useLocation } from "react-router-dom";
import cloneDeep from "clone-deep";

import AuthContext from "./AuthContext";
import useApi from "../hooks/useApi";

import { IUser } from "../types/users";
import {
  IPackageWithDependencies,
  IVersions,
  PackageStatus,
} from "../types/packages";
import { IUpdatePackageVersion, IUpdateUserChanner } from "../types/api";

import { PAGES, TIME_BETWEEN_REFRESHES } from "../constants";

interface IDataContext {
  users: IUser[] | null;
  packages: IPackageWithDependencies[] | null;
  handleUpdatePackageVersion: (params: IUpdatePackageVersion) => void;
  handleUpdateUserChannel: (params: IUpdateUserChanner) => void;
}

const DataContext = createContext({} as IDataContext);

export const DataContextProvider: React.FC<{ children: ReactElement }> = ({
  children,
}) => {
  const { token, isLogged } = useContext(AuthContext);
  const [users, setUsers] = useState<IUser[] | null>(null);
  const [packages, setPackages] = useState<IPackageWithDependencies[] | null>(
    null
  );
  const location = useLocation();
  const { getUsers, getPackages, updatePackageVersion, updateUserChannel } =
    useApi();
  const handleUpdatePackageVersion = async ({
    token,
    packagesToUpdate,
    channelId,
  }: IUpdatePackageVersion) => {
    if (!packages) return;

    const response = await updatePackageVersion({
      token,
      packagesToUpdate,
      channelId,
    });
    if (!response) return;
    await refreshPackages();
  };

  const checkForVersionsInProgress = (versions: IVersions) => {
    return [
      versions.korian_beta.status,
      versions.korian_available.status,
      versions.korian_public.status,
    ].includes(PackageStatus.InProgress);
  };

  const isContainingActivatingPackage = useCallback((pck: IPackageWithDependencies[]) => {
    let isActivating = false;

    pck.forEach((pckg) => {
      if(isActivating) return;
      if (checkForVersionsInProgress(pckg.versions)) {
        isActivating = true;
      } else if (pckg.dependencies.length !== 0) {
        pckg.dependencies.forEach((dependency) => {
          if (checkForVersionsInProgress(dependency.versions)) {
            isActivating = true;
          }
        });
      }
    });
    return isActivating;
  }, []);

  const refreshPackages = useCallback(async () => {
    const response: IPackageWithDependencies[] = await getPackages(token);
    if (!response) return;
    setPackages(response);
    if (isContainingActivatingPackage(response)) {
      setTimeout(async () => await refreshPackages(), TIME_BETWEEN_REFRESHES);
    }
  }, [getPackages, token, isContainingActivatingPackage]);

  const handleUpdateUserChannel = async (params: IUpdateUserChanner) => {
    const response = await updateUserChannel(params);
    if (!response || !users) return;
    const newUsers: IUser[] = cloneDeep(users).map((user) => {
      if (user.userId === params.userId) {
        user.channelId = params.channelId;
      }
      return user;
    });
    setUsers(newUsers);
  };

  useEffect(() => {
    if (!isLogged) return;
    if (location.pathname === PAGES.USERS.URL && !users) {
      const fetchUsers = async () => {
        const response = await getUsers(token);
        if (!response) return;
        setUsers(response);
      };
      fetchUsers().catch((error) => console.log(error));
    }
    if (location.pathname === PAGES.VERSIONS.URL && !packages) {
      const fetchPackages = async () => {
        const response = await getPackages(token);
        if (!response) return;
        setPackages(response);
        if (isContainingActivatingPackage(response)) {
          setTimeout(async () => await refreshPackages(), TIME_BETWEEN_REFRESHES);
        }
      };
      fetchPackages().catch((error) => console.log(error));
    }
  }, [
    isLogged,
    location.pathname,
    users,
    packages,
    token,
    getPackages,
    getUsers,
    refreshPackages,
    isContainingActivatingPackage
  ]);

  return (
    <DataContext.Provider
      value={{
        users,
        packages,
        handleUpdatePackageVersion,
        handleUpdateUserChannel,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

export default DataContext;
